<template>
  <v-form>
    <slot></slot>
    <base-info-card class="login-form px-5"
    title="Login">
      <base-text-field label="Username (email)" name="username" v-model="form.user" @keyup.enter="$refs.pass.$el.querySelector('input').focus()" />

      <base-text-field label="Password" type="password" name="password" v-model="form.pass" @keyup.enter.native="trylogin" ref="pass"/>

      <v-card-actions>
        <v-spacer></v-spacer>
        <base-re-captcha sitekey="6LcueVUeAAAAAPfZym52ffmdm_24pDCV2HxsOcEY"
        color="blue darken-1" text
      @onSubmit="trylogin"
      action="login"
    >
      Login
    </base-re-captcha> 
      </v-card-actions>
    </base-info-card>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data:()=>({
        form:{
          user:'',
          pass:''
        }
    }),

    methods:{
    ...mapActions(['login','showSnackbar']),
    
    trylogin(){
      this.login({user:this.form.user,pass:this.form.pass})
      .then((res)=>{
        //console.log(res);
        if(res.data.data.token){
          this.showSnackbar({
              showing:true,
              message: `Welcome, ${res.data.data.name}!`,
              color:'success'
            });
            this.$router.push('app');
        }
      }).catch(res => {
        if(res.data.errors){
          //console.log(res.data.errors);
          this.showSnackbar({
            showing:true,
            message:res.data.errors[0],
            color:'error'
          });
        }
      })
    },
        reply(){
            this.$emit('close',false);
        }
    }
};
</script>

<style>
</style>
