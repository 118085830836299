<template>
  <v-container
    fill-height
    fluid
    class="ma-0 pa-0 overflow-hidden"
    :class="
      this.$vuetify.breakpoint.name == 'xs' ||
      this.$vuetify.breakpoint.name == 'sm'
        ? 'mobile-background-color'
        : ''
    "
  >
    <v-row justify="center" class="fill-height ma-0 pa-0">
      <v-col
        class="fill-height mt-8 mt-md-0 d-flex align-center"
        cols="12"
        md="5"
      >
      <v-tabs v-model="tab" show-arrows background-color="deep-purple accent-4" icons-and-text dark grow>
        <v-tabs-slider color="purple darken-4"></v-tabs-slider>
        <v-tab v-for="i in tabs" :key="i.name">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1">{{ i.name }}</div>
        </v-tab>
        <v-tab-item>
          <login-form><v-row justify="center" class="fill-height ma-0 pa-0"> <v-img
              src="\img\icons\android-chrome-192x192.png"
              max-width="320px"
              class="ml-4 mb-0"
              align="center"
              tag="img"
            ></v-img></v-row>
          </login-form>
        </v-tab-item>
        <v-tab-item>
          <register-form>
          </register-form>
        </v-tab-item>
      </v-tabs>
      </v-col>
      <v-col
        class="justify-center align-center fill-height d-none d-md-flex ma-0 right-background"
        cols="12"
        md="7"
      >
        <v-card color="transparent" flat dark width="60%">
          <v-card-title class="mb-2 text-h3 font-weight-bold text-break-word white--text text-capitalize">
            Sistema Vigilante
          </v-card-title>
          <v-card-subtitle class="subtitle-1">
            Encuentra tus dispositivos de manera facil y segura.
            Revive la trayectoria en los reportes.
            Crea tus usuarios de solo visita.
          </v-card-subtitle>
          <v-card-actions>
            <v-btn color="blue" to="/about">
              <v-icon dense class="mr-2">fas fa-headphones</v-icon>
              Acerca de nosotros
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.mobile-background-color {
  background-color: #F6F8FA !important;
}
.right-background {
  background-color: #035a80;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23000' stroke-width='66.7' stroke-opacity='0.05' %3E%3Ccircle fill='%23035a80' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%230a557c' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23104f77' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23144a73' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%2318456e' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%231b4069' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%231e3b64' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%2320365e' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%23223059' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23232b53' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%2324264e' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23242148' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23251c42' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%2324173c' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23241336' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%23230d30' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%2322072a' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23210024' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.toast {
  /* margin-top: 50px !important; */
  bottom: 0px !important;
}
.text-break-word {
  word-break: break-word !important;
}
.overflow-hidden {
  overflow-y: auto !important;
}
</style>

<script>
import loginForm from '../components/loginForm.vue';
import registerForm from '../components/registerForm.vue';

export default {
  components: { loginForm,registerForm },
  metaInfo: { title: 'Login' },
  name: "Login",
  created() {
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      tab: 0,
      tabs: [
          {name:"Login", icon:"mdi-account"},
          {name:"Register", icon:"mdi-account-outline"}
      ],
    };
  },
 };
</script>
