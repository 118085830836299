<template>
  <v-form ref="registration">
      <base-info-card class="register-form px-5"
      title="Registro">
        <base-text-field label="Nombre" v-model="form.name" 
            @keyup.enter="$refs.email.$el.querySelector('input').focus()" ref="name"
            :rules="[() => !!form.name || 'This field is required']"
         />
        <base-text-field label="Email (Username)" v-model="form.email" 
            @keyup.enter="$refs.pass.$el.querySelector('input').focus()" ref="email"
            :rules="[rules.required, rules.email]"
         />
        <base-text-field label="Password" type="password" v-model="form.password" 
            @keyup.enter="$refs.passconfirm.$el.querySelector('input').focus()" ref="pass"
            :rules="[() => !!form.password || 'This field is required']"
        />
        <base-text-field label="Password Confirmation" type="password" v-model="form.passconfirm" 
            @keyup.enter.native="tryRegister" ref="passconfirm"
            :rules="[() => !!form.passconfirm || 'This field is required',
                     () => !!form.passconfirm && form.passconfirm == form.password || 'Passwords must match.'
                    ]"
        />
        <v-card-actions>
            <v-spacer></v-spacer>
            <base-re-captcha sitekey="6LcueVUeAAAAAPfZym52ffmdm_24pDCV2HxsOcEY"
                color="blue darken-1" text
                @onSubmit="tryRegister"
                action="register"
                > Registrar 
            </base-re-captcha>
        </v-card-actions>
      </base-info-card>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data:()=>({
        form:{
          email:'',
          password:'',
          name:'',
          passconfirm:'',
        },
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        }
    }),

    methods:{
        ...mapActions(['addUser','showSnackbar']),
        
        tryRegister(){
            //if(this.form.user||)
            const isValid = this.$refs.registration.validate();

            if(isValid){
                this.addUser(this.form)
                .then((res)=>{
                    //console.log(res);
                    if(res.data.name){
                    this.showSnackbar({
                        showing:true,
                        message: `You can now login, ${res.data.name}!`,
                        color:'success'
                        });
                        this.$router.push('app');
                    }
                }).catch(res => {
                    if(res.data.errors){
                    //console.log(res.data.errors);
                    this.showSnackbar({
                        showing:true,
                        message:res.data.errors[0],
                        color:'error'
                    });
                    }
                });
            }
        }
        
    }
}
</script>

<style>

</style>